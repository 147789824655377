const {violette} = require('./colors/violette');
const {turquoise} = require('./colors/turquoise');
const {rose} = require('./colors/rose');
const {getContrastRatio} = require("./colorManipulator");

const light = {
    text: {
        // The most important text.
        primary: violette.dark,
        //primary: 'rgba(0, 0, 0, 0.87)',
        // Secondary text.
        secondary: 'rgba(0, 0, 0, 0.54)',
        // Disabled text have even lower visual prominence.
        disabled: 'rgba(0, 0, 0, 0.38)',
        // Text hints.
        hint: 'rgba(0, 0, 0, 0.38)',
    },
    background: violette.grey,
    backgroundSecondary: violette.black
};

const dark = {
    text: {
        primary: turquoise.normal,
        secondary: 'rgba(255, 255, 255, 0.7)',
        disabled: 'rgba(255, 255, 255, 0.5)',
        hint: 'rgba(255, 255, 255, 0.5)',
        icon: 'rgba(255, 255, 255, 0.5)',
    },
    background: violette.dark,
    backgroundSecondary: violette.grey
};

exports.createPalette = () => {
    const primary = {
        light: turquoise.light2,
        main: turquoise.normal,
        dark: turquoise.dark1,
    };
    const secondary = {
        light: rose.light2,
        main: rose.normal,
        dark: rose.dark1,
    };
    const contrastThreshold = 3;

    function getContrastText(background) {
        // Use the same logic as
        // Bootstrap: https://github.com/twbs/bootstrap/blob/1d6e3710dd447de1a200f29e8fa521f8a0908f70/scss/_functions.scss#L59
        // and material-components-web https://github.com/material-components/material-components-web/blob/ac46b8863c4dab9fc22c4c662dc6bd1b65dd652f/packages/mdc-theme/_functions.scss#L54
        const contrastText =
            getContrastRatio(background, dark.text.primary) >= contrastThreshold
                ? dark.text.primary
                : light.text.primary;

        if (process.env.NODE_ENV !== 'production') {
            const contrast = getContrastRatio(background, contrastText);
            if(contrast >= 3){
                console.log(`Material-UI: the contrast ratio of ${contrast}:1 for ${contrastText} on ${background}\nfalls below the WACG recommended absolute minimum contrast ratio of 3:1.\nhttps://www.w3.org/TR/2008/REC-WCAG20-20081211/#visual-audio-contrast-contrast')`);
            }
        }

        return contrastText;
    }

    const type = 'dark';

    const types = { dark, light };

    return {
            // The colors used to represent primary interface elements for a user.
            primary,
            // The colors used to represent secondary interface elements for a user.
            secondary,
            // The colors used to represent interface elements that the user should be made aware of.
//            grey,
            // Used by `getContrastText()` to maximize the contrast between the background and
            // the text.
            contrastThreshold,
            // Take a background color and return the color of the text to maximize the contrast.
            getContrastText,
            // The light and dark type object.
            ...types[type],
        };
};