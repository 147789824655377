const {createBreakpoints} = require('./createBreakpoints');
const {createPalette} = require('./createPalette');

exports.createJssTheme = () => {
    const palette = createPalette();
    const breakpoints = createBreakpoints();

    return {
        breakpoints,
        palette,
    };
};