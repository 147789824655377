import {combineReducers} from 'redux'
/*
{
    filter: ['f1', 'f2'],
    filterOpen: bool,

    competitions: {
        'Vorrunde 1': {
            groups: [],
            participants: [],
        }
    },
    groups: {
        id: {
            participantsByGroup: []
        }
    }
    participantsById: {
        id: {name, slam, scores}
    }

    gallery: {
        currentImage: int
        lightbox: bool
    }
}
*/

const currentImage = (state = 0, action) => {
    switch(action.type){
        case 'OPEN_LIGHTBOX':
            return action.image;
        case 'PREVIOUS_LIGHTBOX':
            if(state===0){
                return state;
            }
            return state - 1;
        case 'NEXT_LIGHTBOX':
            return state + 1;
        default:
            return state;
    }
};

const lightbox = (state = false, action) => {
    switch(action.type){
        case 'OPEN_LIGHTBOX':
            return true;
        case 'CLOSE_LIGHTBOX':
            return false;
        default:
            return state;
    }
};

const gallery = combineReducers({
    currentImage,
    lightbox,
});

const competitions = (state = {}, action) => {
    switch(action.type){
        case 'RECEIVE_COMPETITIONS':{
            state = {...state};
            action.competitions.forEach(competition => {
                let groups = [];
                let participants = [];
                competition.groups.forEach(group => {
                    groups.push(group.id);
                    group.participants.forEach(participant => {
                        participants.push(participant.id);
                    });
                });
                state[competition.name] = {
                    groups,
                    participants,
                }
            });
            return state;
        }
        default:
            return state;
    }
};

const groups = (state = {}, action) => {
    switch(action.type){
        case 'RECEIVE_COMPETITIONS':{
            state = {...state};
            action.competitions.forEach(competition => {
                competition.groups.forEach(group => {
                    let participants = [];
                    group.participants.forEach(participant => {
                        participants.push(participant.id);
                    });
                    state[group.id] = {
                        participants,
                    }
                });
            });
            return state;
        }
        default:
            return state;
    }
};

const participantsById = (state = {}, action) => {
    switch(action.type){
        case 'RECEIVE_COMPETITIONS':{
            state = {...state};
            action.competitions.forEach(competition => {
                competition.groups.forEach(group => {
                    group.participants.forEach(participant => {
                        state[participant.id] = participant;
                    });
                });
            });
            return state;
        }
        default:
            return state;
    }
};

const filterOpen = (state = false, action) => {
    switch(action.type){
        case 'TOOGLE_NEWS_FILTER':{
            return !state;
        }
        default:
            return state;
    }
};

const filter = (state = [], action) => {
    switch(action.type){
        case 'SET_NEWS_FILTER':{
            return action.filter
        }
        default:
            return state;
    }
};

const reducers = combineReducers({
    filter,
    filterOpen,
    competitions,
    groups,
    participantsById,
    gallery,
});

export default reducers;