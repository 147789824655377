/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import {ThemeProvider} from 'react-jss';

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk'
import reducers from './src/reducers';

import {createJssTheme} from "./src/utils/createJssTheme";
import {receiveCompetitions} from "./src/actions/index";

import result from './src/assets/results/results-export.json';

// remove the JSS style tag generated on the server to avoid conflicts with the one added on the client
export const onInitialClientRender = () => {
    const ssStyles = window.document.getElementById(`server-side-jss`);
    ssStyles && ssStyles.parentNode.removeChild(ssStyles);
};

// eslint-disable-next-line react/prop-types
export  const wrapRootElement = ({ element }, options) => {
    const store = createStore(reducers, applyMiddleware(
        thunkMiddleware
    ));

    store.dispatch(receiveCompetitions(result));

    const theme = createJssTheme();
    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                {element}
            </Provider>
        </ThemeProvider>
    );
};