
//—> ROAD-TO-SLAM 2018

exports.rose = {
//ROSE
    light1: '#ef9e9e',
    light2: '#fcd4af',
    normal: '#ef7b7c',
    dark1: '#ec6586',
    dark2: '#cf5876',
    dark3: '#b34c67',
};