export const setNewsFilter = (filter) =>{
    return {
        type: 'SET_NEWS_FILTER',
        filter
    }
};
export const toogleNewsFilter = () =>{
    return {
        type: 'TOOGLE_NEWS_FILTER',
    }
};

export const receiveCompetitions = (competitions) => {
    return {
        type: 'RECEIVE_COMPETITIONS',
        competitions
    }
};

export const openLightbox = (image) => {
    return {
        type: 'OPEN_LIGHTBOX',
        image
    };
};

export const closeLightbox = () => {
    return {
        type: 'CLOSE_LIGHTBOX',
    };
};

export const previousLightbox = () => {
    return {
        type: 'PREVIOUS_LIGHTBOX',
    };
};

export const nextLightbox = () => {
    return {
        type: 'NEXT_LIGHTBOX',
    };
};