const keys = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl'];

function createBreakpoints() {
    const values = {
        xxs: 480,
        xs: 600,
        sm: 840,
        md: 960,
        lg: 1024,
        xl: 1280,
        xxl: 1440,
        xxxl: 1600
    };
    const unit = 'px';
    const step = 5;

    function getGutter(key) {
        switch (key){
            case 'xxs':
            case 'xs':
                return 16;
            case 'sm':
            case 'md':
            case 'lg':
            case 'xl':
            case 'xxl':
            case 'xxxl':
            default:
                return 24;
        }
    }
    function getColumn(key) {
        switch (key){
            case 'xxs':
                return 4;
            case 'xs':
            case 'sm':
                return 8;
            case 'md':
            case 'lg':
            case 'xl':
            case 'xxl':
            case 'xxxl':
            default:
                return 12;
        }
    }

    function getWidth(key, column) {
        const gutter = getGutter(key);
        if(!column) {
            return `calc(100% - ${4*gutter}${unit})`;
        }
        const columnTotal = getColumn(key);
        const gabTotal = (columnTotal+1)*gutter;
        const gab = (column+1)*gutter;
        const columnFactor = column / columnTotal;
        return `calc((100% - ${columnTotal+1} * ${gutter}${unit}) / ${columnTotal} * ${column} + ${column+1} * ${gutter}${unit})`;
//        return `calc((100% - ${gabTotal}${unit}) * ${columnFactor} + ${gab}${unit})`;
    }

    function up(key) {
        const value = typeof values[key] === 'number' ? values[key] : key;
        return `@media (min-width:${value}${unit})`;
    }

    function down(key) {
        const value = typeof values[key] === 'number' ? values[key] : key;
        return `@media (max-width:${value - step / 100}${unit})`;
    }

    function between(start, end) {
        const endIndex = keys.indexOf(end);

        if (endIndex === keys.length) {
            return up(start);
        }

        return (
            `@media (min-width:${values[start]}${unit}) and ` +
            `(max-width:${values[keys[endIndex]] - step / 100}${unit})`
        );
    }

    function only(key) {
        return between(key, key);
    }

    function width(key) {
        return values[key];
    }

    return {
        keys,
        values,
        up,
        down,
        between,
        only,
        width,
        getWidth,
    };
}

exports.createBreakpoints = createBreakpoints;